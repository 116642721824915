var Gtm = require('../../../components/gtm');

$('.subscribe-social').on('click', function (e) {
    e.preventDefault();
    var url = $(this).data('href');
    var button = $(this);
    var emailId = $('input[name=socialEmailSignUp]').val();
    $.spinner().start();
    $(this).attr('disabled', true);
    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: {
            emailId: emailId
        },
        success: function (data) {
            displayMessage(data, button);
            Gtm.eventNewOptin(Gtm.NEW_OPTIN_SOURCE.HOME);
            dataLayer.push({
                event: 'formComplete',
                eventCategory: 'form',
                eventLabel: 'newsletter'
            });
        },
        error: function (err) {
            displayMessage(err, button);
        }
    });
});

function displayMessage(data, button) {
    $.spinner().stop();
    var status;
    if (data.success) {
        status = 'alert-success';
    } else {
        status = 'alert-danger';
    }

    if ($('.email-signup-message').length === 0) {
        $('body').append(
           '<div class="email-signup-message"></div>'
        );
    }
    $('.email-signup-message')
        .append('<div class="email-signup-alert text-center ' + status + '">' + data.msg + '</div>');

    setTimeout(function () {
        $('.email-signup-message').remove();
        button.removeAttr('disabled');
    }, 3000);
}
